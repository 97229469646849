@mixin font($size, $weight: 500, $color: var(--ion-color-font), $transform: none) {
  font: {
    size: $size;
    weight: $weight;
  }
  color: $color;
  text-transform: $transform;
  text-decoration: none;
}

@mixin placeholder($opacity: 0.5, $color: var(--ion-color-font)) {
  opacity: $opacity;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  color: $color;
}

@mixin background-gradient() {
  background: var(--ion-color-primary-gradient);
}
