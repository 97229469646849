@use "shared/index.scss" as *;

ion-app {
  /* Split pane page title */
  ion-split-pane {
    ion-title {
      font-size: 1.5rem;
    }
  }
}

/* Ion Toolbar */
ion-toolbar {
  --ion-toolbar-background: var(--ion-color-background);
  --border-width: 0 !important;

  &:has(.main-header-title) {
    display: flex;
    align-items: center;
    min-height: 60px;
  
    .main-header-title {
      @include font($color: var(--ion-color-font), $size: 1.9rem, $weight: bold);
      text-align: left;
      padding-inline: spacing(1.5)
    }
  }
}

/* Content, header and item background */
ion-content,
ion-header,
ion-item {
  --ion-background-color: var(--ion-color-background);
  --background: var(--ion-color-background);
}

ion-content {
  --padding-start: #{spacing(2)};

  ion-item {
    --padding-start: 0;
  }
}

/* Ion Header */
ion-header {
  box-shadow: none !important;
  border-bottom: none;

  &:not([collapse="condense"]):not(.header-collapse-condense-inactive).header-collapse-main {
    border-bottom: 1px solid var(--ion-color-border);
  }

  &:not([collapse="condense"]) {
    @include tablet() {
      margin-top: spacing(1);
    }
  }

  &:has(.main-header-title) {
    display: flex;
    align-items: flex-end;
    background: var(--ion-color-background);
    padding-top: spacing(2);
  }
}

gd-modal-header {
  ion-header {
    &:not([collapse="condense"]) {
      @include tablet() {
        margin-top: 0;
      }
    }
  }
}

/* Ion List Header */
ion-list-header {
  @include font($size: 1.2rem, $weight: 700, $color: var(--ion-color-font));
  padding: spacing(1) 0;

  ion-icon {
    font-size: 1.5rem;
    margin-right: spacing(1);
  }
}

ion-accordion {
  ion-item.accordion-header {
    --ion-color-base: var(--ion-color-background);
    --padding-start: 0;
    --background-activated: transparent;
  }
}

/* Ion Footer */
ion-footer {
  box-shadow: none !important;

  &.solid-footer {
    background-color: var(--ion-color-background);
    padding: spacing(2);
    box-shadow: 0 0 5px var(--ion-color-border);

    &--transparent {
      box-shadow: none;
    }

    &.footer-md::before {
      height: 0;
    }
  }
  &.border-footer {
    border-top: 0.5px solid var(--ion-color-border);
  }
}

/* Title */
ion-title {
  @include font($size: 1.1rem, $color: var(--ion-color-font), $weight: bold);
  text-align: center;

  &.title-large {
    @include font($size: 1.8rem, $weight: 700, $color: var(--ion-color-font));
  }
}

/* Ion Searchbar */
ion-searchbar {
  --border-radius: #{spacing(3)} !important;

  .searchbar-input.sc-ion-searchbar-md {
    border: 1px solid var(--ion-color-border);
    padding: 0 0 0 spacing(6);
    padding-top: 0;
    padding-bottom: 0;
    height: spacing(5);
  }

  .searchbar-search-icon.sc-ion-searchbar-md {
    top: 10px;
  }

  &.sc-ion-searchbar-ios-h,
  &.sc-ion-searchbar-md-h {
    padding: 0;
    min-height: auto;
  }

  &.sc-ion-searchbar-md-h {
    --box-shadow: none !important;
  }

  &.sc-ion-searchbar-md {
    padding-inline-end: spacing(.5) !important;
  }
}

/* Input */
ion-input {
  --placeholder-opacity: .5 !important;
  --padding-start: 0 !important;
  min-height: auto !important;

  &::placeholder {
    @include placeholder();
  }
}

/* Textarea */
ion-textarea {
  min-height: spacing(10) !important;

  textarea {
    padding: 10px 0 0 0 !important;

    &::placeholder {
      @include placeholder();
    }
  }

  .label-text-wrapper {
    align-self: flex-start !important;
  }
}

.md ion-textarea textarea {
  padding: spacing(2) 0 0 0 !important;
}

/* Input and textarea labels */
ion-input,
ion-textarea {
  &:not(.custom-input) {
    .label-text-wrapper {
      @include font($size: 0.9rem);
      min-width: 140px;
      max-width: 140px;
      width: 140px;
      margin: 0 !important;
      margin-right: spacing(1);
    }
  }

  .label-text-wrapper {
    margin: 0 !important;
    align-self: center;
  }

  .native-textarea {
    @include font($size: 0.9rem !important, $color: var(--ion-color-font) !important);
  }
}

/* Datetime */
ion-datetime {
  max-width: 100%;
  --background: transparent;
  --wheel-highlight-background: var(--ion-color-border);

  &::part(calendar-day) {
    color: var(--ion-color-font);
    border: none;
    font-weight: 400;

    &:focus {
      background: var(--ion-color-font-light);
      box-shadow: none;
    }
  }

  &::part(today) {
    font-weight: 500;
  }

  &::part(active) {
    color: var(--ion-color-background);
    background-color: var(--ion-color-font-light);

    &:focus {
      box-shadow: none;
    }
  }

  &::part(wheel-item) {
    background: transparent;

    &:focus {
      background: transparent;
    }
  }

  &::part(wheel-item active) {
    color: var(--ion-color-font);
    font-weight: 500;
  }

  &::part(month-year-button) {
    color: var(--ion-color-font);
  }
}

/* Datetime button */
ion-datetime-button {
  &::part(native) {
    color: var(--ion-color-font);
    font-weight: 700;
    font-size: 1rem;
  }
}

/* Checkbox */
ion-checkbox {
  --checkbox-background-checked: var(--ion-color-primary) !important;
  --border-color-checked: var(--ion-color-primary) !important;
}

/* Toast */
ion-toast {
  --border-radius: #{spacing(1.5)};
  @include font($size: 0.9rem, $color: var(--ion-color-white));

  &::part(container) {
    min-height: 50px;
  }

  &::part(icon) {
    font-size: 1.5rem;
    margin-inline-start: spacing(1);
  }
}

ion-toggle {
  @include font($size: 0.9rem, $color: var(--ion-color-font));
  width: 100%;
}

/* Select */
ion-select {
  --placeholder-opacity: 0.5 !important;
  --padding-start: 0;
  --highlight-color: var(--ion-color-medium);

  &::part(placeholder) {
    @include placeholder();
  }

  &.readonly::part(icon) {
    display: none !important;
  }
}

select.select-card-type {
  @include font($size: 1rem, $weight: 700, $color: var(--ion-color-font));
  height: spacing(5);
  width: 100%;
  background-color: var(--ion-color-background);
  color: var(--ion-color-font);
  border-radius: spacing(1);
  border: 1px solid var(--ion-color-border);
  padding: 0 spacing(1);
}

/** Item */
ion-item {
  --ion-item-color: var(--ion-color-secondary);
  --padding-start: #{spacing(2)};
  --inner-padding-end: #{spacing(1)};
  --highlight-background: var(--ion-color-light);
  --show-full-highlight: 0;
}

/* Ion Button */
ion-button {
  --color: var(--ion-color-primary) !important;
  --ripple-color: transparent !important;
}

/* Back button */
ion-back-button {
  --color: var(--ion-color-primary) !important;
  --icon-font-size: 1.5rem;
  --icon-margin-start: 0;
  --padding-start: 0;
  --background-hover: none !important;
}

/* Refresher */
ion-refresher {
  z-index: var(--z-index-fixed);
  height: #{spacing(3.5)};

  ion-spinner {
    height: spacing(2.5) !important;
  }
}

/* Alert */
ion-alert {
  .alert-title {
    @include font($size: 1.2rem, $weight: 700, $color: var(--ion-color-font));
    text-align: center;
    margin-bottom: spacing(1) !important;
  }

  .alert-sub-title,
  .alert-message {
    @include font($size: 1rem, $color: var(--ion-color-font));
  }

  &.select-alert,
  &.vehicle-selector__select {
    .alert-head,
    .alert-message {
      display: none;
    }
  }

  .alert-button.sc-ion-alert-ios,
  .alert-button.sc-ion-alert-md {
    color: var(--ion-color-primary);
  }

  .alert-input {
    --ion-background-color: transparent;
  }
}

/* Action sheet */
ion-action-sheet {
  .action-sheet-button {
    color: var(--ion-color-font) !important;
    font-weight: 500;
  }

  .sc-ion-action-sheet-md {
    text-align: center !important;
    color: var(--ion-color-font) !important;
  }

  .action-sheet-button-inner.sc-ion-action-sheet-md {
    justify-content: center;
    font-weight: 700;
  }

  .action-sheet-cancel .sc-ion-action-sheet-md {
    font-weight: 400;
  }

  .action-sheet-group-cancel.sc-ion-action-sheet-md {
    border-top: 1px solid var(--ion-color-border);
  }

  .action-sheet-selected.sc-ion-action-sheet-md {
    color: var(--ion-color-primary) !important;
  }

  .action-sheet-button.sc-ion-action-sheet-ios {
    font-size: 1rem;
  }

  .action-sheet-button.sc-ion-action-sheet-ios .action-sheet-icon.sc-ion-action-sheet-ios {
    font-size: 1.4em !important;
  }

  .action-sheet-group.sc-ion-action-sheet-ios:first-child {
    max-height: 60vh;
  }

  .action-sheet-title {
    color: var(--ion-color-font) !important;
  }

  .as-icon .action-sheet-button-inner {
    justify-content: flex-start;
  }

  .as-delete .action-sheet-button-inner {
    color: var(--ion-color-danger);
  }
}

/* Sliding */
ion-item-sliding ion-item-option {
  min-width: spacing(10);
}

ion-item-options.item-options-ios.item-options-end {
  border-bottom-width: 0 !important;
}

ion-picker {
  .picker-toolbar.sc-ion-picker-md {
    justify-content: space-between;
  }
}

/* Ion Modal */
ion-modal {
  @include tablet() {
    --height: 90%;
    --width: 70%;
    --max-width: 700px;
    --max-height: 1000px;
  
    &#detail-image {
      --height: 100%;
      --width: 100%;
      --max-width: 100%;
      --max-height: 100%;
    }
  }
}

/* Dark styles */
[theme="dark"] {
  ion-searchbar {
    --color: var(--ion-color-medium) !important;
    --background: var(--ion-color-dark) !important;
  }

  ion-alert {
    --color: var(--ion-color-secondary) !important;
    --background: var(--ion-color-black) !important;
  }

  ion-item {
    --border-color: var(--ion-color-border) !important;
  }

  ion-toolbar {
    --border-color: var(--ion-color-border);
  }

  ion-accordion {
    .ion-accordion-toggle-icon {
      color: var(--ion-color-font);
    }
  }

  ion-label,
  ion-spinner {
    --color: var(--ion-color-font) !important;
  }

  ion-picker {
    --background: var(--ion-color-ultra-dark) !important;
    --border-width: 0;
    --border-color: var(--ion-color-border);
    color: var(--ion-color-font);

    .picker-below-highlight {
      background: linear-gradient(to top, var(--background, var(--ion-background-color, #fff)) 30%, rgba(var(--background-rgb, var(--ion-background-color-rgb, 24, 30, 38)), 0.8) 100%);
    }
    .picker-above-highlight {
      background: linear-gradient(to bottom, var(--background, var(--ion-background-color, #fff)) 20%, rgba(var(--background-rgb, var(--ion-background-color-rgb, 24, 30, 38)), 0.8) 100%)
    }
  }

  ion-datetime {
    --color: var(--ion-color-secondary) !important;
    --background: var(--ion-color-background) !important;
    --wheel-highlight-background: var(--ion-color-ultra-dark) !important;
    --wheel-fade-background-rgb: var(--ion-color-medium) !important;
  }

  ion-datetime-button {
    &::part(native) {
      background-color: var(--ion-color-dark);
    }
  }

  ion-toggle {
    --track-background: var(--ion-color-ultra-dark) !important;
  }

  ion-checkbox {
    --border-color: var(--ion-color-secondary) !important;
  }

  ion-select {
    color: var(--ion-color-font-light);
  }

  ion-textarea {
    .textarea-wrapper {
      color: var(--ion-color-font);
    }
  }

  ion-ripple-effect {
    color: var(--ion-color-light) !important;
  }

  ion-skeleton-text {
    --background: var(--ion-color-dark) !important;
    background-color: var(--ion-color-dark) !important;
  }
  
  ion-action-sheet {
    --color: var(--ion-color-secondary) !important;
    --background: var(--ion-color-black) !important;
    --button-background-selected: var(--ion-color-dark) !important;

    .action-sheet-button {
      color: var(--ion-color-font-light) !important;
    }

    .action-sheet-cancel.sc-ion-action-sheet-ios::after {
      background-color: var(--ion-color-black) !important;
    }

    .action-sheet-selected::after {
      background: var(--ion-color-ultra-dark) !important;
    }
  }


  ion-reorder {
    color: var(--ion-color-font) !important;
  }

  ion-alert {
    .alert-title,
    .alert-message {
      color: var(--ion-color-font) !important;
    }
  }
}

/* iOS styles */
[mode="ios"] {
  /* Fix bug on iOS with ion router outlet presenting element */
  ion-router-outlet {
    border-radius: spacing(2) !important;
  }

  ion-footer {
    &.solid-footer {
      padding: spacing(2) spacing(2) spacing(3) spacing(2);   
    }
  }
}

/* Android styles */
[mode="md"] {
  ion-label {
    text-transform: none;
  }

  ion-modal {
    --border-radius: #{spacing(1.5)};
  }

  ion-toolbar {
    --min-height: #{spacing(6)};
  }

  ion-header {
    box-shadow: none;
    border: none;

    &.header-collapse-condense {
      display: block;
    }

    ion-title:not(.main-header-title):not(.main-body-title) {
      position: absolute;
      inset: 0 0 0 0;
      margin: auto;
      width: fit-content;
      max-width: 55%;
    }
  }

  ion-list {
    padding: 0;
  }

  ion-button {
    &.button {
      text-transform: none !important;
      letter-spacing: normal;
      font-size: 1.1rem;
    }
  }
}