:root {
  /* Colors */
  --ion-color-primary: #e47443;
  --ion-color-primary-75: #e47443bf;
  --ion-color-primary-50: #e4744380;
  --ion-color-primary-25: #e4744340;

  --ion-color-secondary: #515154;
  --ion-color-secondary-75: #515154bf;
  --ion-color-secondary-50: #51515480;
  --ion-color-secondary-25: #51515440;

  --ion-color-white: #ffffff;
  --ion-color-white-75: #ffffffbf;
  --ion-color-white-50: #ffffff80;
  --ion-color-white-25: #ffffff40;

  --ion-color-ultra-light: #f3f3f3;
  --ion-color-ultra-light-75: #f3f3f3bf;
  --ion-color-ultra-light-50: #f3f3f380;
  --ion-color-ultra-light-25: #f3f3f340;

  --ion-color-light: #e3e0e0;
  --ion-color-light-75: #e3e0e0bf;
  --ion-color-light-50: #e3e0e080;
  --ion-color-light-25: #e3e0e040;

  --ion-color-medium: #aaabad;
  --ion-color-medium-75: #aaabadbf;
  --ion-color-medium-50: #aaabad80;
  --ion-color-medium-25: #aaabad40;

  --ion-color-dark: #212121;
  --ion-color-dark-75: #212121bf;
  --ion-color-dark-50: #21212180;
  --ion-color-dark-25: #21212140;

  --ion-color-ultra-dark: #161616;
  --ion-color-ultra-dark-75: #161616bf;
  --ion-color-ultra-dark-50: #16161680;
  --ion-color-ultra-dark-25: #16161640;

  --ion-color-black: #000000;
  --ion-color-black-75: #000000bf;
  --ion-color-black-50: #00000080;
  --ion-color-black-25: #00000040;

  --ion-color-success: #3caf6a;
  --ion-color-success-75: #3caf6abf;
  --ion-color-success-50: #3caf6a80;
  --ion-color-success-25: #3caf6a40;

  --ion-color-warning: #ffc409;
  --ion-color-warning-75: #ffc409bf;
  --ion-color-warning-50: #ffc40980;
  --ion-color-warning-25: #ffc40940;

  --ion-color-danger: #d02f2f;
  --ion-color-danger-75: #d02f2fbf;
  --ion-color-danger-50: #d02f2f80;
  --ion-color-danger-25: #d02f2f40;

  --ion-color-purple: #653bad;
  --ion-color-purple-75: #653badbf;
  --ion-color-purple-50: #653bad80;
  --ion-color-purple-25: #653bad40;

  --ion-color-primary-gradient: linear-gradient(180deg, #ff3131 5%, #ff9140 100%);
  --ion-color-purple-gradient: linear-gradient(91deg, rgb(57, 31, 105) -2%, rgb(115, 43, 155) 44.4%, rgb(231, 75, 184) 100%);

  --ion-color-background: #ffffff;
  --ion-color-border: #e3e0e0;
  --ion-color-font: #313133;
  --ion-color-font-light: #aaabad;

  /* Z-Index */
  --z-index-zero: 0;
  --z-index-fixed: 1000;
  --z-index-dynamic-modal: 21000;
  --z-index-loading: 22000;
}

/* Dark variables */
html[theme="dark"] {
  --ion-color-primary: #e38754;
  --ion-color-primary-75: #e38754bf;
  --ion-color-primary-50: #e3875480;
  --ion-color-primary-25: #e3875440;

  --ion-color-secondary: #4b4b4f;
  --ion-color-secondary-75: #4b4b4fbf;
  --ion-color-secondary-50: #4b4b4f80;
  --ion-color-secondary-25: #4b4b4f40;

  --ion-color-white: #f0f0f0;
  --ion-color-white-75: #f0f0f0bf;
  --ion-color-white-50: #f0f0f080;
  --ion-color-white-25: #f0f0f040;

  --ion-color-ultra-light: #2a2a2c;
  --ion-color-ultra-light-75: #2a2a2cbf;
  --ion-color-ultra-light-50: #2a2a2c80;
  --ion-color-ultra-light-25: #2a2a2c40;

  --ion-color-light: #1f1f21;
  --ion-color-light-75: #1f1f21bf;
  --ion-color-light-50: #1f1f2180;
  --ion-color-light-25: #1f1f2140;

  --ion-color-medium: #88898b;
  --ion-color-medium-75: #88898bbf;
  --ion-color-medium-50: #88898b80;
  --ion-color-medium-25: #88898b40;

  --ion-color-dark: #1a1a1d;
  --ion-color-dark-75: #1a1a1dbf;
  --ion-color-dark-50: #1a1a1d80;
  --ion-color-dark-25: #1a1a1d40;

  --ion-color-ultra-dark: #1e1e21;
  --ion-color-ultra-dark-75: #1e1e21bf;
  --ion-color-ultra-dark-50: #1e1e2180;
  --ion-color-ultra-dark-25: #1e1e2140;

  --ion-color-black: #101010;
  --ion-color-black-75: #101010bf;
  --ion-color-black-50: #10101080;
  --ion-color-black-25: #10101040;

  --ion-color-success: #329d5f;
  --ion-color-success-75: #329d5fbf;
  --ion-color-success-50: #329d5f80;
  --ion-color-success-25: #329d5f40;

  --ion-color-warning: #e3aa07;
  --ion-color-warning-75: #e3aa07bf;
  --ion-color-warning-50: #e3aa0780;
  --ion-color-warning-25: #e3aa0740;

  --ion-color-danger: #b02b2b;
  --ion-color-danger-75: #b02b2bbf;
  --ion-color-danger-50: #b02b2b80;
  --ion-color-danger-25: #b02b2b40;

  --ion-color-purple: #5c359c;
  --ion-color-purple-75: #5c359cbf;
  --ion-color-purple-50: #5c359c80;
  --ion-color-purple-25: #5c359c40;

  --ion-color-background: #141414;
  --ion-color-border: #2b2b2b;
  --ion-color-font: #d4d4d4;
  --ion-color-font-light: #9a9a9a;
}
