$tablet: 768px;
$desktop: 1061px;

@mixin tablet {
  @media (min-width: $tablet) and (min-height: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop)  and (min-height: $tablet) {
    @content;
  }
}