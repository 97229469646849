@use "shared/index.scss" as *;

:host {
  --content-height: 100%;
}

html {
  --ion-dynamic-font: var(--ion-default-dynamic-font);
  font-size: 16px;

  &[mode="ios"] {
    --ion-default-font: -apple-system, blinkmacsystemfont, "Helvetica Neue", sans-serif;
  }
  &[mode="md"] {
    --ion-default-font: "Helvetica Neue", sans-serif;
  }

  &.plt-mobileweb * {
    user-select: text;
  }
}

/* Body */
body {
  @include tablet() {
    background-color: var(--ion-color-ultra-light);
  }
}

/* Ad banner styles */
body.ad-banner {
  /* Reduce global container */
  ion-split-pane {
    margin-bottom: spacing(13);
  }

  ion-router-outlet {
    /* Tabs styles */
    gd-tabs {
      margin-bottom: spacing(7.5);
  
      @include tablet() {
        margin-bottom: spacing(12);
      }
    }
  }
}

/* Ad banner styles iOS */
[mode="ios"] {
  body.ad-banner {
    ion-router-outlet {
      /* Pages (Not tab pages adjustments). Note: not gd-tabs because after login has can-go-back class */
      .ion-page.can-go-back:not(gd-tabs) {
        ion-content {
          --padding-bottom: #{spacing(12)};
        }
        ion-footer {
          margin-bottom: spacing(10);
        }
      }
    }
  }
}

/* Ad banner styles Android */
[mode="md"] {
  body.ad-banner {
    ion-router-outlet {
      /* Pages (Not tab pages adjustments). Note: not gd-tabs because after login has can-go-back class */
      .ion-page.can-go-back:not(gd-tabs) {
        ion-content {
          --padding-bottom: #{spacing(7)};
        }
        ion-footer {
          margin-bottom: spacing(7.5);
        }
      }
    }
  }
}

* {
  outline: none !important;
}

[hidden] {
  display: none !important;
}

/* h2 default styles */
h2 {
  @include font($size: 1.5rem, $weight: bold);
  margin: spacing(1) 0;
}

/* h3 default styles */
h3 {
  @include font($size: 1.3rem, $weight: bold);
  margin: spacing(1) 0;
}

/* h4 default styles */
h4 {
  @include font($size: 1.1rem);
  margin: spacing(1) 0;
}

/* p default styles */
p {
  margin: 0;
}

.gd-logo {
  border-radius: spacing(2);
}

/* Default form styles */
form {
  /* Input label */
  ion-item.item ion-label:not(.custom-input):not(.input-file__input-wrapper):not(.input-form__input-wrapper).sc-wrapper-ion-label-ios-h,
  ion-item.item ion-label:not(.custom-input):not(.input-file__input-wrapper):not(.input-form__input-wrapper).sc-ion-label-ios-h,
  ion-item.item ion-label:not(.custom-input):not(.input-file__input-wrapper):not(.input-form__input-wrapper).sc-ion-label-md-h,
  ion-item.input-form:not(.custom-input) ion-label:not(.custom-input):not(.input-form__input-wrapper) {
    @include font($size: 0.9rem);
    min-width: 140px;
    max-width: 140px;
    width: 140px;
    margin: 0 !important;
    margin-right: spacing(1);
  }
}

/* Form fields default font styles */
ion-input,
ion-textarea,
ion-select,
ion-datetime,
input:not(.searchbar-input):not(.stripe-input):not(.input-card__input),
select,
.datalist,
.custom-input,
.sc-ion-input-ios-h,
.sc-ion-input-md-h {
  @include font($size: 0.9rem, $weight: 700, $color: var(--ion-color-font));

  &::placeholder {
    @include placeholder();
  }
}

/* Select components into form styles*/
form ion-select {
  --padding-end: 0 !important;

  &::part(icon) {
    display: none !important;
  }
}

/* input default styles */
input:focus {
  outline: none;
}

.c-p {
  cursor: pointer !important;
}

.bold {
  font-weight: 700;
}

.readonly {
  pointer-events: none;
}

.button {
  transition-property: border, background-color;
  transition-duration: 1s;
  transition-timing-function: ease-out;
}

/* Modals */
.ion-datetime-button-overlay {
  --backdrop-opacity: 0.3 !important;
}

.datetime-form__value {
  width: 100% !important;
  max-width: 100% !important;
}

/* Small modals */
ion-modal#folder-form,
ion-modal#file-form {
  --width: fit-content;
  --height: fit-content;
  --min-width: 280px;
  --max-width: 280px;
  --max-height: calc(75vh - 270px);
  --border-radius: #{spacing(2)};
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  margin-top: -25vh;

  .modal-wrapper {
    background-color: var(--ion-color-background);
  }

  @include tablet() {
    --width: 60%;
    --min-width: 60%;
    --max-width: 60%;
    margin-top: 0;
  }

  ion-toolbar {
    border-bottom: 1px solid var(--ion-color-border);
  }
}

/* Custom alerts */
ion-alert#promotional-code {
  margin-top: -25vh;
}

.header-md::after {
  bottom: -3px !important;
}

.main-body-title {
  @include font($size: 1.8rem, $weight: 700, $color: var(--ion-color-font));
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 90%;
  padding: 0;
}

.add-icon {
  font-size: 1.6em !important;
}

form ion-label.label-fixed {
  @include tablet() {
    max-width: none;
    min-width: 180px;
  }
}

/* Button */
ion-button.in-toolbar,
ion-back-button.in-toolbar {
  --color: var(--ion-color-font) !important;
}

ion-picker .picker-button {
  color: var(--ion-color-primary) !important;
}

.ion-button-bold {
  font-weight: 700;
}

/* Ellipsis class */
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Wrong field */
.wrong-field {
  color: var(--ion-color-danger) !important;
}

/* Disabled */
.disabled {
  opacity: .5 !important;
  pointer-events: none;
  transition: opacity 0.2s linear;
}

/* Number plate class */
.number-plate {
  padding: #{spacing(.5)} !important;
  border-radius: spacing(1) !important;
}

/* Detail */
.detail-data {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: spacing(1);
  padding-right: spacing(2);
  gap: spacing(1.5);

  &--no-gap {
    margin-top: spacing(-1.5);
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__subtitle {
    @include font($size: 1rem, $color: var(--ion-color-font-light));
  }

  &__number-plate-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__role-wrapper {
    display: flex;
    gap: spacing(1);
  }

  &__tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(1);
  }
}

.detail-extra-info-item {
  &__value {
    @include font(
      $size: 1rem !important,
      $color: var(--ion-color-font) !important,
    );
    text-align: right;
  }

  &__img {
    max-height: spacing(4);
    border-radius: spacing(.5);
    aspect-ratio: auto;
    color: var(--ion-color-font);
  }
}

.empty-list {
  @include font($size: 1rem, $color: var(--ion-color-font));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;

  ion-icon {
    @include font($size: 64px, $color: var(--ion-color-font));
  }
}

/* Card */
.card {
  display: flex;
  flex-direction: row;
  padding: spacing(1);
  background-color: var(--ion-color-background);
  border: 1px solid var(--ion-color-border);
  border-radius: spacing(1.5);
}

.content-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: auto;
}

/* Ripple styles */
.ripple-parent {
  position: relative;
  overflow: hidden;
}

/* Custom badge styles */
.alert-badge {
  display: block;
  background-color: var(--ion-color-primary);
  border-radius: spacing(2);
  height: spacing(1);
  width: spacing(1);
}

/* Username check styles */
.username{
  position: relative;

  ion-icon {
    font-size: 1.5rem;
    position: absolute;
    bottom: spacing(1);
    right: spacing(1);
  }

  &--pending {
    position: absolute;
    right: spacing(1);
    top: spacing(1.5);
    height: spacing(2.5);
    color: var(--ion-color-font);
  }

  &--available {
    color: var(--ion-color-success);
  }

  &--unavailable {
    color: var(--ion-color-danger);
  }
}

/* Input type search title */
.input-card {
  &__title {
    @include font($size: 0.8rem !important, $color: var(--ion-color-font-light));
    display: block !important;
    margin-left: spacing(.5) !important;
  }
}

/* Sliding option item */
.sliding-options__item {
  font-size: 1.2rem;
}

.alert-title {
  text-align: center;
}

.alert-client-access-data,
.alert-tire-pressure {
  .alert-title {
    color: var(--ion-color-font);
    text-align: center;
    margin-bottom: spacing(3);
  }

  .alert-sub-title,
  .alert-message {
    @include font($size: 0.9rem, $color: var(--ion-color-font));
    text-align: left !important;
  }
}

/* Quick actions */
.quick-actions {
  @include font($size: 1.5rem, $color: var(--ion-color-font));
  align-self: center;
  margin-left: spacing(1);
  cursor: pointer;

  @include desktop() {
    margin: 0 spacing(1) 0 spacing(2);
  }
}

/* Cookies banner */
.cc-window {
  padding: spacing(6) spacing(3) !important;
  box-shadow: 0 4px 70px 0 rgba(163,163,163,1);
  width: 100%;
  max-width: none !important;
  bottom: 0 !important;

  .cc-deny {
    border-color: var(--ion-color-black) !important;
  }
}

/* Dark styles */
[theme="dark"] {
  body {
    --ion-backdrop-color: var(--ion-color-secondary);
  }

  .card {
    background-color: var(--ion-color-ultra-dark);
    border: none;
  }

  .number-plate {
    color: var(--ion-color-font-light);
  }

  .quick-actions {
    color: var(--ion-color-font-light);
  }

  .empty-list {
    color: var(--ion-color-font-light);

    ion-icon {
      color: var(--ion-color-font-light);
    }
  }
}